<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    size="small"
                    shadow="never"
                    v-if="goods.supplierQuotationsStatus && goods.supplierQuotationsStatus !== 10"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="8">
                            <el-form-item
                                label="提交时间:"
                                label-width="9em"
                            >
                                {{ goods.submitTime  | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="报价状态:"
                                label-width="9em"
                            >
                                {{ goods.supplierQuotationsStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="审核时间:"
                                label-width="9em"
                            >
                                {{ goods.auditTime  | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="审核不通过原因:"
                                label-width="9em"
                                v-if="goods.supplierQuotationsStatus === 99"
                            >
                                {{ goods.auditRemarks }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>商品信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="商品名称:"
                                label-width="9em"
                            >
                                {{ goods.goodsName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品简称:"
                                label-width="9em"
                            >
                                {{ goods.goodsShortName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品副标题:"
                                label-width="9em"
                            >
                                {{ goods.goodsSubtitle }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品货号:"
                                label-width="9em"
                            >
                                {{ goods.goodsSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品分类:"
                                label-width="9em"
                            >
                                {{ goods.goodsCatNamePath }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="商品图片:"
                                label-width="9em"
                            >
                                <image-list :data="goods.goodsImageList"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="商品详情图:"
                                label-width="9em"
                            >
                                <image-list :data="goods.goodsDetailImageList"/>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>商品报价</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="发货仓库:"
                                label-width="9em"
                                prop="warehouseId"
                            >
                                <RemoteSelect
                                    filterable
                                    remote="/gd/warehouse/supplierWarehouseList"
                                    placeholder="请输入"
                                    :props="{id:'id',name:'warehouseName'}"
                                    v-model="formModel.warehouseId"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <!-- 表格 -->
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="skuList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        label="规格图片"
                                        width="120"
                                    >
                                        <template slot-scope="scope">
                                            <image-list :data="scope.row.specImageUrl"/>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="skuSn"
                                        label="sku编码"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        label="商品规格"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="price"
                                        label="报价"
                                        min-width="150"
                                    >
                                        <template slot-scope="scope">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="2"
                                                v-model.number="scope.row.price"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="stock"
                                        label="库存"
                                        min-width="150"
                                    >
                                        <template slot-scope="scope">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="0"
                                                v-model.number="scope.row.stock"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="thirdPlatformSkuSn"
                                        label="sku编码"
                                        min-width="150"
                                    >
                                        <template slot-scope="scope">
                                            <el-input
                                                v-model="scope.row.thirdPlatformSkuSn"
                                                placeholder="请输入sku编码"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="thirdPlatformSkuQuantity"
                                        label="规格值"
                                        min-width="150"
                                    >
                                        <template slot-scope="scope">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="0"
                                                v-model.number="scope.row.thirdPlatformSkuQuantity"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                            <el-form-item>
                                <span
                                    class="m-t"
                                >
                                    <span class="batch">批量设置: </span>
                                    <template v-for="(item,index) in formField">
                                        <el-button
                                            :key="index"
                                            v-if="!item.hide"
                                            @click="onBatch(item)"
                                        >
                                            {{ item.name }}
                                        </el-button>
                                    </template>
                                </span>
                            </el-form-item>
                            <el-form-item
                                label-width="13em"
                            >
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirm"
                                >
                                    提交审核
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="$router.back()"
                                >
                                    返 回
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                goodsId: '',
                warehouseId: '',
            },
            // 表单校验规则
            formRules: {
                warehouseId: {
                    required: true,
                    message: '请选择发货仓库',
                    trigger: 'blur',
                },
            },
            goods: {},
            skuList: [],
        };
    },
    computed: {
        formField() {
            return [
                { key: 'price', name: '价格', required: true },
                { key: 'stock', name: '库存', required: true },
                { key: 'thirdPlatformSkuQuantity', name: '规格值', required: true },
            ];
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Gd.SupplierQuotations.saveSupplierQuotations({
                    ...this.formModel,
                    skuListJson: JSON.stringify(this.skuList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Gd.SupplierQuotations.getSupplierQuotations({ goodsId: this.formModel.goodsId }).then(json => {
                const res = json.data.data;
                this.goods = res.goods;
                this.skuList = res.skuList;
                this.formModel.warehouseId = this.goods.warehouseId;
            });
        },
        onBatch(field) {
            this.$prompt(`请输入${field.name}`, '温馨提示', {
                inputValidator(val) {
                    if (!val && field.required) {
                        return `${field.name}不能为空`;
                    }
                    if (/price|stock|costPrice|thirdPlatformSkuQuantity/.test(field.key) && Number.isNaN(val)) {
                        return `请填写`;
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|stock|thirdPlatformSkuQuantity/.test(field.key)) {
                    value = +value;
                }
                this.skuList.forEach(item => {
                    item[field.key] = value;
                });
            });
        },
    },
    created() {
        this.formModel.goodsId = this.$route.params.goodsId;
        if (this.formModel.goodsId) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
