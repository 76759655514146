<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.supplierQuotationsStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="待审核"
                    name="20"
                />
                <el-tab-pane
                    label="审核通过"
                    name="100"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="supplierName"
                    label="供应商"
                >
                    <el-input
                        v-model="queryFormModel.supplierName"
                        placeholder="请输入供应商"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="warehouseId"
                    label="发货仓库"
                >
                    <RemoteSelect
                        filterable
                        remote="/gd/warehouse/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'warehouseName'}"
                        v-model="queryFormModel.warehouseId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        size="small"
                        @click="onBatchAudit(100)"
                        :disabled="!currentTableSelect.length"
                        v-if="queryFormModel.supplierQuotationsStatus == 20"
                    >
                        批量审核通过
                    </el-button>
                    <el-button
                        type="success"
                        size="small"
                        @click="onBatchAudit(99)"
                        :disabled="!currentTableSelect.length"
                        v-if="queryFormModel.supplierQuotationsStatus == 20"
                    >
                        批量审核不通过
                    </el-button>
                </el-button-group>
            </div>
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="supplierName"
                    label="供应商"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="60"
                />
                <el-table-column
                    prop="goodsCatNamePath"
                    label="商品类目"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    width="70"
                />
                <el-table-column
                    prop="warehouseName"
                    label="发货仓库"
                    min-width="60"
                />
                <el-table-column
                    prop="stock"
                    label="库存"
                    width="70"
                />
                <el-table-column
                    prop="supplierQuotationsStatusName"
                    label="报价状态"
                    width="90"
                />
                <el-table-column
                    prop="auditRemarks"
                    label="审核不通过原因"
                    min-width="60"
                    v-if="queryFormModel.supplierQuotationsStatus == 99"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="60"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/supplierQuotations/supplierQuotationsAudit/edit/${scope.row.id}`)"
                        >
                            {{ scope.row.supplierQuotationsStatus == 20 ? '审核' : '查看' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierQuotationsStatus: '20',
                warehouseId: '',
                supplierName: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.SupplierQuotations.quotationsGoodsList({
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onBatchAudit(status) {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.$confirm(status === 100 ? '是否确定批量审核通过！' : '是否确定批量审核不通过！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Gd.SupplierQuotations.batchAudit({ ids, supplierQuotationsStatus: status }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
