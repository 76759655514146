<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="warehouseId"
                    label="发货仓库"
                >
                    <RemoteSelect
                        filterable
                        remote="/gd/warehouse/supplierWarehouseList"
                        placeholder="请输入"
                        :props="{id:'id',name:'warehouseName'}"
                        v-model="queryFormModel.warehouseId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="60"
                />
                <el-table-column
                    prop="goodsCatNamePath"
                    label="商品类目"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    width="70"
                />
                <el-table-column
                    prop="warehouseName"
                    label="发货仓库"
                    min-width="60"
                />
                <el-table-column
                    prop="stock"
                    label="库存"
                    width="70"
                />
                <el-table-column
                    prop="supplierQuotationsStatusName"
                    label="报价状态"
                    width="90"
                />
                <el-table-column
                    prop="auditRemarks"
                    label="审核不通过原因"
                    min-width="60"
                    v-if="queryFormModel.supplierQuotationsStatus == 99"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="110"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/supplierQuotations/supplierQuotations/edit/${scope.row.goodsId}`)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onEditStockGoods(scope.row)"
                            v-if="scope.row.supplierQuotationsStatus === 100"
                        >
                            修改库存
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="修改库存"
            center
            width="1200px"
            :visible.sync="onEditStockDialog.isVisible"
            @closed="onEditStockDialogCancel"
        >
            <el-form
                ref="onEditStockForm"
                size="small"
                :model="onEditStockDialog.formModel"
                :rules="onEditStockDialog.formRules"
            >
                <el-form-item>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="onEditStockDialog.skuList"
                        style="width: 100%"
                        :span-method="spanMethod"
                    >
                        <el-table-column
                            label="SKU编码"
                            min-width="100"
                            prop="skuSn"
                        />
                        <el-table-column
                            label="SKU规格"
                            min-width="100"
                            prop="specDesc"
                        />
                        <el-table-column
                            label="库存"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    v-model.number="scope.row.stock"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="thirdPlatformSkuSn"
                            label="sku编码"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.thirdPlatformSkuSn"
                                    placeholder="请输入sku编码"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="thirdPlatformSkuQuantity"
                            label="规格值"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="0"
                                    v-model.number="scope.row.thirdPlatformSkuQuantity"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="cell">
                        <span class="batch">批量设置: </span>
                        <template v-for="(item,index) in formField">
                            <!-- TODO vue/no-use-v-if-with-v-for 这不是一个好的解决方案，应该是处理 formField 数据时就过滤掉-->
                            <el-button
                                v-if="!item.hide"
                                :key="index"
                                @click="onBatch(item)"
                            >
                                {{ item.name }}
                            </el-button>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditStockDialogConfirm"
                >
                    修改库存
                </el-button>
                <el-button
                    size="small"
                    @click="onEditStockDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--选择会员-->
        <el-dialog
            title="选择商品"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="selectDialog.queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        label="商品图片"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.mainImageUrl"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品货号"
                        min-width="60"
                    />
                    <el-table-column
                        prop="goodsCatName"
                        label="商品类目"
                        min-width="60"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="$router.push(`/gd/supplierQuotations/supplierQuotations/edit/${scope.row.id}`)"
                            >
                                新增报价
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierQuotationsStatus: '100',
                warehouseId: '',
                isAvailable: '1',
                isAlarmStock: '1',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            // 商品上架
            onEditStockDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    goodsId: '',
                    skuListJson: '',
                },
                // 表单校验规则
                formRules: {},
                // 商品详情列表
                skuList: [],
                // 商品规格
                goodsSpecList: [],
            },
            formField: [{ key: 'stock', name: '库存', required: true }, { key: 'id', name: 'id', hide: true }],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品货号
                    goodsCatId: '', // 商品类目
                    supplierQuotationsNE: 1,
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.SupplierQuotations.quotationsGoodsList({
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        // 修改库存
        onEditStockGoods(row) {
            this.onEditStockDialog.formModel.goodsId = row.goodsId;
            this.$api.Gd.SupplierQuotations.getSupplierQuotations({
                goodsId: this.onEditStockDialog.formModel.goodsId,
            }).then(json => {
                const res = json.data.data;
                this.onEditStockDialog.skuList = res.skuList;
                this.onEditStockDialog.isVisible = true;
            });
        },
        onEditStockDialogConfirm() {
            const { onEditStockForm } = this.$refs;
            onEditStockForm.validate().then(() => {
                this.$api.Gd.SupplierQuotations.onEditQuotationsStock({
                    goodsId: this.onEditStockDialog.formModel.goodsId,
                    skuListJson: JSON.stringify(this.onEditStockDialog.skuList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onEditStockDialog.isVisible = false;
                    this.onQuery(this.pagination);
                });
            });
        },
        onEditStockDialogCancel() {
            const { onEditStockForm } = this.$refs;
            onEditStockForm.resetFields();
            const { formModel } = this.onEditStockDialog;
            formModel.goodsId = '';
            this.onEditStockDialog.isVisible = false;
        },
        onBatch(field) {
            this.$prompt(`请输入${field.name}`, '温馨提示', {
                inputValidator(val) {
                    if (!val && field.required) {
                        return `${field.name}不能为空`;
                    }
                    if (/price|stock|costPrice/.test(field.key) && Number.isNaN(val)) {
                        return `请填写金额`;
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|stock/.test(field.key)) {
                    value = +value;
                }
                this.onEditStockDialog.skuList.forEach(item => {
                    item[field.key] = value;
                });
            });
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
</style>
