<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template
            slot="body"
        >
            <el-form
            >
                <el-card
                    size="small"
                    shadow="never"
                    v-if="goods.supplierQuotationsStatus && goods.supplierQuotationsStatus !== 10"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="8">
                            <el-form-item
                                label="提交时间:"
                                label-width="9em"
                            >
                                {{ goods.submitTime  | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="报价状态:"
                                label-width="9em"
                            >
                                {{ goods.supplierQuotationsStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="8"
                            v-if="goods.supplierQuotationsStatus !== 20">
                            <el-form-item
                                label="审核时间:"
                                label-width="9em"
                            >
                                {{ goods.auditTime  | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="8"
                            v-if="goods.supplierQuotationsStatus !== 20">
                            <el-form-item
                                label="审核人:"
                                label-width="9em"
                            >
                                {{ goods.auditUserName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="审核不通过原因:"
                                label-width="9em"
                                v-if="goods.supplierQuotationsStatus === 99"
                            >
                                {{ goods.auditRemarks }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>商品信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="商品名称:"
                                label-width="9em"
                            >
                                {{ goods.goodsName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品简称:"
                                label-width="9em"
                            >
                                {{ goods.goodsShortName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品副标题:"
                                label-width="9em"
                            >
                                {{ goods.goodsSubtitle }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品货号:"
                                label-width="9em"
                            >
                                {{ goods.goodsSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品分类:"
                                label-width="9em"
                            >
                                {{ goods.goodsCatNamePath }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="商品图片:"
                                label-width="9em"
                            >
                                <image-list :data="goods.goodsImageList"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="商品详情图:"
                                label-width="9em"
                            >
                                <image-list :data="goods.goodsDetailImageList"/>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>商品报价</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-form-item
                                label="供应商:"
                                label-width="9em"
                            >
                                {{ goods.supplierName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="发货仓库:"
                                label-width="9em"
                            >
                                {{ goods.warehouseName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <!-- 表格 -->
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="skuList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        label="规格图片"
                                        width="120"
                                    >
                                        <template slot-scope="scope">
                                            <image-list :data="scope.row.specImageUrl"/>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="skuSn"
                                        label="sku编码"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        label="商品规格"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="price"
                                        label="报价"
                                        min-width="100"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="stock"
                                        label="库存"
                                        min-width="100"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="thirdPlatformSkuSn"
                                        label="sku编码"
                                        min-width="100"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="thirdPlatformSkuQuantity"
                                        label="规格值"
                                        min-width="100"
                                    >
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="goods.supplierQuotationsStatus === 20"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>报价审核</span>
                    </div>
                    <div class="item">
                        <el-form
                            ref="addForm"
                            size="small"
                            :model="formModel"
                            :rules="formRules"
                        >
                            <el-form-item
                                prop="supplierQuotationsStatus"
                                label="审核是否通过:"
                                label-width="8em"
                            >
                                <el-select
                                    v-model="formModel.supplierQuotationsStatus"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        :key="99"
                                        label="审核不通过"
                                        :value="99"
                                    />
                                    <el-option
                                        :key="100"
                                        label="审核通过"
                                        :value="100"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                prop="auditRemarks"
                                label="不通过原因:"
                                label-width="8em"
                                v-if="formModel.supplierQuotationsStatus === 99"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.auditRemarks"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                label="售卖优先级:"
                                label-width="8em"
                                v-if="formModel.supplierQuotationsStatus === 100"
                            >
                                <el-form-item>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="supplierQuotationsList"
                                        style="width: 100%"
                                    >
                                        <el-table-column type="expand">
                                            <template slot-scope="scope">
                                                <el-table
                                                    stripe
                                                    border
                                                    size="small"
                                                    :data="scope.row.skuList"
                                                    style="width: 100%"
                                                >
                                                    <el-table-column
                                                        prop="skuSn"
                                                        label="sku编码"
                                                        min-width="100"
                                                    />
                                                    <el-table-column
                                                        prop="specDesc"
                                                        label="商品规格"
                                                        min-width="100"
                                                    />
                                                    <el-table-column
                                                        prop="price"
                                                        label="报价"
                                                        min-width="100"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="stock"
                                                        label="库存"
                                                        min-width="100"
                                                    >
                                                    </el-table-column>
                                                </el-table>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="supplierName"
                                            label="供应商"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="warehouseName"
                                            label="发货仓库"
                                            min-width="60"
                                        />
                                        <el-table-column
                                            prop="stock"
                                            label="库存"
                                            width="70"
                                        />
                                        <el-table-column
                                            label="是否可用"
                                            width="70"
                                        >
                                            <template slot-scope="scope">
                                                <el-switch
                                                    v-model="scope.row.isAvailable"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="优先级（越大优先级越高）"
                                            width="170"
                                        >
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    v-model.number="scope.row.orderNum"
                                                />
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>

                <el-card
                    size="small"
                    class="ma-t"
                >
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            v-if="goods.supplierQuotationsStatus === 20"
                            type="primary"
                            size="small"
                            @click="onConfirm()"
                        >
                            提交
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            formModel: {
                id: '',
                supplierQuotationsStatus: '',
                auditRemarks: '',
            },
            formRules: {
                supplierQuotationsStatus: {
                    required: true,
                    message: '请选择审核是否通过',
                },
                auditRemarks: {
                    required: true,
                    message: '请输入不通过原因',
                    trigger: 'blur',
                },
            },
            goods: {},
            skuList: [],
            supplierQuotationsList: [],
        };
    },
    methods: {
        init() {
            this.$api.Gd.SupplierQuotations.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.goods = res.goods;
                this.skuList = res.skuList;
                this.supplierQuotationsList = res.supplierQuotationsList;
            });
        },
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定提交！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Gd.SupplierQuotations.auditSupplierQuotations({
                        ...this.formModel,
                        ids: this.id,
                        supplierQuotationsListJson: JSON.stringify(this.supplierQuotationsList),
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
